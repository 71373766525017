import { render, staticRenderFns } from "./artificial-intelligence-in-supply-chain-management.vue?vue&type=template&id=221a853e&"
import script from "./artificial-intelligence-in-supply-chain-management.vue?vue&type=script&lang=js&"
export * from "./artificial-intelligence-in-supply-chain-management.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports