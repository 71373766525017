<template>
    <div class="main-limiter margin-bottom-5">
        <article class="main">
            <h1>Artificial Intelligence in Supply Chain Management</h1>
            <DoroImage :src="ImageMain" max-width="300px"></DoroImage>

            <p>The industrial revolution that changed the way the world works in the late 19th and early 20th centuries is happening one more time.</p>
            <p>Today we can observe an enormous effort in increasing the automation of planning and execution of every-day tasks. In this article we will show examples from a few companies: DHL, Zalando, Otto, and Hitachi how one can benefit from AI in supply chain management.</p>
            <p>As the <URL to="https://image-src.bcg.com/Images/Supply_Chain_Management_Jan_2015_tcm9-62525.pdf">experts from BCG note</URL>:</p>
            <blockquote><p>We frequently find that by using big data and advanced analytical techniques to deal with tough supply-chain problems such as these, companies can identify opportunities for savings equal to 15 to 20 percent of transportation costs.</p></blockquote>
            <p>Let's view it example by example.</p>

            <h2>DHL</h2>
            <p>DHL has recently published, together with IBM, a <URL to="https://www.logistics.dhl/content/dam/dhl/global/core/documents/pdf/glo-core-trend-report-artificial-intelligence.pdf">report</URL> on possible applications and benefits of AI in logistics. The report caught our attention as it shows a complex multi-level data transformation of a logistics company.</p>
            <DoroImage :src="imageG966" square hack-rendering>
                <template v-slot:image-caption>
                    source: <URL to="https://www.logistics.dhl/content/dam/dhl/global/core/documents/pdf/glo-core-trend-report-artificial-intelligence.pdf">Artificial intelligence in logistics</URL>
                </template>
            </DoroImage>

            <h4>The suggested transformation consists of i.a. of:</h4>

            <p><strong>Computer vision-based inventory scanning and management.</strong></p>
            <p>Using modern deep learning techniques and object detection models such as Faster-RCNN or YOLOv3 it is possible to create a system that automatically orders the items that are missing on the shelves of a warehouse. </p>
            <p>Similar systems are nowadays commonly used in supermarket chains and they warn the staff that a product has to be replenished. Software like this can be deployed either on stationary cameras or drones, for example with the help of NVIDIA Jetson or Google Coral hardware.</p>

            <p><strong>Voice-enabled WMS and ERP system.</strong></p>
            <p>In warehouses, every hand plays a role. Often one has to pick and move packages, operate vehicles such as forklifts, and might not have any free hand available. Since the 90s, there has been observed a trend in creating voice-controlled software for depots and warehouses.</p>
            <p>By using modern speech recognition and natural language processing techniques, every feature of a mature WMS or ERP system can be used with voice control. It's almost as if the workers had a third arm!</p>

            <p><strong>Vision-based intelligent sorting.</strong></p>
            <p>For logistics companies, it is vital to maximizing the utilization of available transportation means. The packing of goods comes in many different shapes and structures. Nowadays, computer vision systems can detect the 3D shape of the object and optimize the required storage space.</p>

            <blockquote>
                <p><strong>AIREV</strong> specializes in dedicated, custom-tailored solutions for the supply chain management. Our projects include the automatization of back-office work.</p>
                <p>One of the examples of the project was run in 2019. The aim was to automatically analyze the orders and offers from text messages (e-mails) and extract the crucial metadata into the client's database with low latency.</p>
                <p>Imagine what a positive effect on staff morale has such a system – before the transformation they had to manually extract the information about the ordered parts and insert them into the database!</p>
                <p>Marek Bardoński, Managing Partner</p>
            </blockquote>

            <h2>Otto</h2>
            <p>Otto, similarly to Amazon, bases on selling inventory that is not in their stocks. It creates a situation where finding a good balance between the rate of ordering and sending the items defines the profitability of the business.</p>
            <p>The data science team at Otto developed an algorithm using three billion data records and 200 variables, including transactions, search queries on the Otto website, and weather forecasts, to derive a forecast for the coming week's purchases.</p>
            <p>The AI system proved to be markedly successful. It can predict, with 90% accuracy, which will be sold within the next 30 days. Consequently, Otto is now able to order around 200,000 items in advance each month and to send items more quickly in a single consignment when a customer places an order.</p>
            <p>The outcome: since the introduction of the AI system, the number of returns per year has fallen by around <b>two million items</b>.</p>

            <h2>Hitachi</h2>
            <p>Hitachi claims <URL to="https://www.hitachi.com/rev/archive/2016/r2016_06/pdf/r2016_06_106.pdf">a verified improvement of approximately 8% in the business efficiency of logistics operations</URL>. The system generates the order of operations that should be performed by the employee based on the products that have to be collected and their location. Then the system compares the efficiency of tested processes/procedures with the past performance in a warehouse and improves its future suggestions.</p>

            <h2>Zalando</h2>
            <p>A similar system has been implemented by Zalando. It considers more factors than the employees' routes such as the paths of the careers that might obstruct the chosen path.</p>

            <h2>What's next?</h2>
            <Promo place="logistics"></Promo>
        </article>
    </div>
</template>

<script>
    import { Component, Vue } from 'vue-property-decorator';
    import DoroImage from '@/components/DoroImage';
    import Promo from '@/components/Promo';
    import URL from '@/components/URL';

	import ImageMain from '@/assets/articles/main-photo/artificial-intelligence-in-supply-chain-management.png';
    import imageG966 from '@/assets/articles/artificial-intelligence-in-supply-chain-management/g966.jpg';

    @Component({
        components: {
            DoroImage,
            Promo,
            URL,
        },
        metaInfo() {
            return {
                title: 'Artificial Intelligence in Supply Chain Management',
            }
        }
    })
    export default class AiInTradingAndRetail extends Vue {
		get ImageMain() { return ImageMain; }
		get imageG966() { return imageG966; }
    }
</script>